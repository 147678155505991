<template>
  <div class="container mt-5 mb-5">
    <b-img :src="imgLogo" alt="logo" class="logo"/>
    <privacy-policy-german></privacy-policy-german>
    <privacy-policy-english></privacy-policy-english>
  </div>
</template>

<script>
import {BAlert, BLink, BImg} from 'bootstrap-vue'
import {email, required} from "@core/utils/validations/validations";
import ImprintGerman from "@/views/pages/content/ImprintGerman";
import ImprintEnglish from "@/views/pages/content/ImprintEnglish";
import PrivacyPolicyGerman from "@/views/pages/content/PrivacyPolicyGerman";
import PrivacyPolicyEnglish from "@/views/pages/content/PrivacyPolicyEnglish";

export default {
  components: {
    PrivacyPolicyEnglish,
    PrivacyPolicyGerman,
    BAlert,
    BLink,
    BImg,
  },
  data() {
    return {
      imgLogo: require('@/assets/images/logo/intellyscale.svg'),
    }
  },
}
</script>
<style lang="scss">
.logo {
  width: 200px;
}
</style>
